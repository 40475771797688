/*
 * @Author: zouyaoji@https://github.com/zouyaoji
 * @Date: 2021-10-28 09:20:11
 * @LastEditTime: 2021-10-28 10:02:43
 * @LastEditors: zouyaoji
 * @Description:
 * @FilePath: \vue-cesium@next\packages\components\overlays\wind\glsl\segmentDraw.frag.ts
 */
const text = `
void main() {
  const vec4 white = vec4(1.0);
  out_FragColor = white;
}
`
export default text
